import React from 'react';
import { Link } from 'gatsby'

export default () => (
  <footer className="container-fluid py-5" style={{backgroundColor: '#333'}}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6">
          <img src="../images/logo-big-white.png" className="img-fluid mt-3" style={{width:'90px'}} alt="" />
          <h5 className="footer-slogan">资产运营管理SaaS平台</h5>
        </div>
        <div className="col-12 col-md-6 footer-navbar justify-content-start">
          <span className="title" style={{minWidth: '4rem'}}>站点导航</span>
          <ul className="nav ml-n3">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                首页
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/park">
                园区
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" paintDrip to="/community">
                社区
              </Link>
            </li>
{/*
            <li className="nav-item">
              <Link className="nav-link" paintDrip to="/zhi-hui-tong-xing">
                通行
              </Link>
            </li>
*/}
            <li className="nav-item">
              <Link className="nav-link" paintDrip to="/product">
                产品
              </Link>
            </li>
{/*
            <li className="nav-item">
              <Link className="nav-link" paintDrip to="/zhi-hui-tong-xing">
                价格
              </Link>
            </li>
*/}
              {/*<li className="nav-item">*/}
              {/*    <Link className="nav-link" paintDrip to="/info">*/}
              {/*        付费资讯*/}
              {/*    </Link>*/}
              {/*</li>*/}
            <li className="nav-item">
              <Link className="nav-link" paintDrip to="/about-us">
                联系我们
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <span className="text-white">联系我们</span>
        </div>
        <div className="col-12">
          185-1425-7332 | info@guosim.com | 北京市海淀区中关村创客小镇15号楼2层
        </div>

        {/*<div className="col-lg-2 col-3">*/}
        {/*    <div className="row  align-items-center" style={{height: 50}}>*/}
        {/*        <div className="col-auto" style={{color: '#fff', fontSize: "1.2em"}}>*/}
        {/*            企业微信公众号*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <div className="row">*/}
        {/*        <div className="col-auto">*/}
        {/*            <img src="../images/about-us/3.png" style={{height: 100, width: 100}}/>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*</div>*/}
      </div>

      <hr color="#5b5b5b" />

      <div className="row justify-content-center">
        <div className="col footer-copyright">
          ©2021 北京果心科技有限公司&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank">京ICP备14052195号-1</a>&nbsp;&nbsp;京公网安备11010802026422
        </div>
      </div>
    </div>
  </footer>
);
